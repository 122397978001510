<template>
    <component :is="excelTable"></component>
</template>

<script>
// import menuExcelForm from './excelMenu/menuExcel'

// import areaForm from './downloadExcels/downloadAreas/area'

export default {
  name: 'ExcelTableDetail',
  components: {
    // excelMenu: menuExcelForm,
    // downloadAreas: areaForm,

  },
  data () {
    return {
      excelTable: this.$route.params.table,
    }
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }

  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>
